@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$blue:		#22B0BF;
$green:		#B2BF50;
$orange:	#D28542;

$dark-gray:		#1a2226;
$light-gray:	#b8bec3;

$primary:       $blue;
$secondary:     $green;
$tertiary:			$orange;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: (
	"primary":    $primary,
	"secondary":  $secondary,
	"tertiary":	  $tertiary,
	"success":    $success,
	"info":       $info,
	"warning":    $warning,
	"danger":     $danger,
	"light":      $light,
	"dark":       $dark,
	"black":			$black
);


// Body
// 
// Settings for the `<body>` element.

$body-bg:                   	$white;
$body-color:                	$black;


// Links
//
// Style anchor elements.

$link-color:              		theme-color("primary");
$link-decoration:         		none;
$link-hover-color:        		darken($link-color, 15%);
$link-hover-decoration:				underline;


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   	1rem;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      'Montserrat', sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;

$font-size-base:							1rem; // Assumes the browser default, typically `16px`

$font-weight-lighter:         lighter;
$font-weight-light:           400;
$font-weight-normal:          500;
$font-weight-medium:          600;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         10px;
$input-btn-padding-x:         20px;
$input-btn-font-size:         $font-size-base * 0.75;
$input-btn-line-height:       15px;

$input-btn-padding-y-sm:      5px;
$input-btn-padding-x-sm:      15px;
$input-btn-line-height-sm:    15px;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;
$btn-font-size:               $input-btn-font-size;
$btn-line-height:             $input-btn-line-height;

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-font-weight:             $font-weight-bold;



// 3W MEDIA - CUSTOM STYLING

// theme styling
$theme-color-primary: 				$blue;
$theme-color-primary-dark: 		darken($theme-color-primary, 10%);

$theme-color-secondary: 			$dark-gray;
$theme-color-secondary-dark: 	darken($theme-color-secondary, 10%);

$theme-color-tertiary: 				$light-gray;
$theme-color-tertiary-dark: 	darken($theme-color-tertiary, 10%);

// Eyecatcher
$eyecatcher-height-big: 			70vh;
$eyecatcher-height-small: 		35vh;

$eyecatcher-height-sm-big: 		290px;
$eyecatcher-height-sm-small: 	290px;

// TOMMY
$zeb-color-primary: 			$theme-color-primary;
$zeb-color-secondary: 		$theme-color-secondary;
$zeb-color-tertiary: 			$theme-color-primary-dark;