.page_block {
  &.contentblock {
    &.grid {
      .green-cards & {
        .item {
          @include media-breakpoint-up(sm) {
            @include make-col(12);
          }

          @include media-breakpoint-up(md) {
            @include make-col(6);
          }

          @include media-breakpoint-up(lg) {
            @include make-col(4);
          }

          @include media-breakpoint-up(xl) {
            @include make-col(4);
          }
        }
      }
    }
  }
}