.default {
	&.service_overview {
		.section_one {
			@extend .py-4;

			@extend .px-sm-4;
			@extend .py-sm-5;
		}
	}

	&.service_category {
		.section_one {
			@extend .py-4;

			@extend .px-sm-4;
			@extend .py-sm-5;
		}

		.service_category_detail {
			@include make-row();
			@extend .pb-5;

			.info {
				@include make-col-ready();
				@include make-col(12);

				@extend .mb-4;
				@extend .pr-lg-5;

				@extend .tt-content-text;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}
			}

			.content {
				@extend .d-flex;
				@extend .flex-column;
				
				@include make-col-ready();
				@include make-col(12);
				@extend .mb-4;
				@extend .pl-lg-5;

				@extend .tt-content-text;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				.btn-back {
					@extend .align-self-end;
				}
			}
		}
	}

	&.service_detail {
		.section_one {
			@extend .py-4;

			@extend .px-sm-4;
			@extend .py-sm-5;
		}
			
		.service_detail {
			@include make-row();

			.info {
				@include make-col-ready();
				@include make-col(12);

				@extend .mb-4;
				@extend .pr-lg-5;

				@extend .tt-content-text;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}
			}

			.content {
				@include make-col-ready();
				@include make-col(12);
				@extend .d-flex;
        @extend .flex-column;

				@extend .mb-4;
				@extend .pl-lg-5;

				@extend .tt-content-text;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				.btn-back {
					@extend .align-self-end;
				}
			}
		}
	}
}