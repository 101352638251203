.default {
	&.landings_page {
		
		.section_intro,
		.section_newsletter,
		.section_blocks,
		.section_text_cols  {
			@extend .px-3;
			@extend .px-sm-4;
			@extend .py-3;
			@extend .py-sm-5;
		}
		.section_title {
			@extend .px-3;
			@extend .px-sm-4;
			@extend .pt-3;
			@extend .pt-sm-5;
			@extend .pb-2;
			@extend .pb-sm-4;

			@extend .bg-secondary;
			color: $white;
		}
		.section_newsletter {
			@extend .bg-secondary;
			color: $white;
		}

	}
}
