.list {
  margin: 0;
  padding: 0;

  list-style: none;

  &.list-icons {
    .list-item {
      @extend .d-flex;
      .icon {
        @extend .mr-2;
      }
      .label {
        font-style: italic;
      }
    }
  }
  
}
