.default {
	&.location_overview,
	&.accommodation_overview,
	&.location_accommodation_overview {
		.section_one {
			@extend .py-4;

			@extend .px-sm-4;
			@extend .py-sm-5;
		}

		.footer {
			@extend .pb-0;
		}
	}

	&.accommodation_arrangement_overview {
		.section_one {
			@extend .py-4;

			@extend .px-sm-4;
			@extend .py-sm-5;
		}

		.footer {
			@extend .pb-0;
		}
	}

	&.accommodation_search_book {
		.section_one {
			@extend .py-4;

			@extend .px-sm-4;
			@extend .py-sm-5;
		}

		.footer {
			@extend .pb-0;
		}
	}

	&.accommodation_detail,
	&.location_detail,
	&.location_accommodation_detail,
	&.accommodation_book {
		.section_one {
			@extend .py-4;

			@extend .px-sm-4;
			@extend .py-sm-5;
		}

		.accommodation_detail,
		.location_detail {
			@include make-row();
			@extend .pb-5;

			.info,
			.extra-info {
				@include make-col-ready();
				@include make-col(12);

				@extend .mb-4;
				@extend .pr-lg-5;

				@extend .tt-content-text;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}

				.goto-prices {
					@extend .btn;
					@extend .btn-primary;
					@extend .d-table;
					@extend .my-4;
					i {
						@extend .ml-3;
					}
				}

				.heading {
					@extend .d-flex;
					@extend .flex-wrap;
					@extend .justify-content-between;
					
					@extend .mb-1;

					h1 {
						@extend .mr-3;
						@extend .mb-0;
					}

					.rating {
						@extend .d-flex;
					}
				}

				.location {
					margin-bottom: 10px;
					font-weight: $font-weight-normal;
				}
			}

			.icon {
				font-style: italic;
				i {
					@extend .mr-1;
				}
			}

			.content {
				@include make-col-ready();
				@include make-col(12);
				@extend .mb-4;
				@extend .pl-lg-5;

				@extend .tt-content-text;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}
			}

			.collapse-item {
				.collapse-title {
					position: relative;

					padding: 12px 20px 12px 60px;
					background-color: $secondary;
					border-top: 1px solid $white;

					font-size: $font-size-base;
					font-weight: $font-weight-normal;
					line-height: 19px;
					color: $white;
					cursor: pointer;

					.collapse-icon {
						position: absolute;
						top: 8px;
						left: 20px;

						width: 26px;
						height: 26px;

						background-color: $white;
						border-radius: 50%;

						font-size: $font-size-base;
						line-height: 26px;
						text-align: center;
						color: $secondary;
					}
				}

				.collapse-content {
					display: none;
					padding: 15px;

					background-color: lighten($secondary, 25%);
					border-top: 1px solid $white;
					.gallery {
						@extend .m-0;
					}

					
					
				}

				&:last-of-type {
					.collapse-content {
						@extend .p-0;
						background-color: transparent;
					}
				}

				&.cl-map {
					img {
						width: 100%;
					}
				}
			}

			.tommy {
				@include make-col-ready();
				@include make-col(12);
			}
		}

		.footer {
			@extend .pb-0;
		}
	}
}
