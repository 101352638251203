.default {
	&.blog_post_overview {
		.section_one {
			@extend .py-4;

			@extend .px-sm-4;
			@extend .py-sm-5;
		}
		.blog_overview {
			.card {
				.card-body {
					@extend .pt-4;
					@extend .pb-3;
					.card-caption {
						.card-text {
							@extend .mt-2;
							p {
								line-height: 1rem;;
							}
						}
					}
				}
			}
		}
	}

	&.blog_post_detail {
		.section_one {
			@extend .py-4;

			@extend .px-sm-4;
			@extend .py-sm-5;
		}
			
		.blog_detail {
			@include make-row();

			.info {
				@include make-col-ready();
				@include make-col(12);

				@extend .mb-4;
				@extend .pr-lg-5;

				@extend .tt-content-text;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}
			}

			.content {
				@extend .d-flex;
				@extend .flex-column;

				@include make-col-ready();
				@include make-col(12);
				@extend .mb-4;
				@extend .pl-lg-5;

				@extend .tt-content-text;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}
			}
		}
	}
}
