/* ==========================================================================
   Header
   ========================================================================== */

.header {
  position: relative;
  z-index: 999;

  background-color: $blue;
  transition: $transition-base;

  @include media-breakpoint-up(xl) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    background-color: transparent;
  }

/* Header-main
   ========================================================================== */
  .header-main {
    .column.navbar {
      @extend .align-items-start;
      padding: 15px 20px;

      transition: $transition-base;

      @include media-breakpoint-up(sm) {
        padding: 15px 40px;
      }
    }

    .logo {
      max-width: 150px;
      transition: all 0.5s ease;

      @include media-breakpoint-up(sm) {
        max-width: 225px;
      }

      a {
        @extend .d-block;

        img {
          @extend .w-100;
        }
      }
    }

    .navbar-toggler {
      @extend .ml-auto;
      outline: none;

      @extend .tt-menu-toggler;
      transition: $transition-base;

      .navbar-toggler-icon {
        width: auto;
        height: auto;

        background-image: none;

        @include media-breakpoint-up(xl) {
          text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
        }
      }

      &:hover {
        @extend .tt-menu-toggler--hover;
      }
    }

    .navbar-collapse {
      .navbar-nav {
        @include media-breakpoint-up(xl) {
          position: relative;
          padding: 10px 15px;
          &::before {
            content: "";
            z-index: -1;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            background: rgba(34, 176, 191, 0.75);
            width: 350px;
            }
        }
        @extend .ml-auto;

        .nav-item {
          @extend .text-right;
          @extend .text-xl-right;

          .nav-link {
            padding: 5px 0;

            @extend .tt-menu-main;

            &:hover {
              @extend .tt-menu-main--hover;
            }

            @include media-breakpoint-up(xl) {
              text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
            }
          }

          &.active {
            .nav-link {
              @extend .tt-menu-main--active;
            }
          }

          .dropdown-menu {
            @extend .mt-0;
            @extend .pt-0;
            padding-bottom: 10px;

            background-color: transparent;
            border: none;
            border-radius: 0;

            .nav-item {
              .nav-link {
                padding: 2.5px 15px 2.5px 0;
                background-color: transparent;
                @extend .tt-menu-sub;
              }

              .dropdown-menu {
                @extend .d-none;
                @extend .pb-0;

                .nav-item {
                  padding-right: 30px;
                }
              }
            }
          }
        }
      }
    }

    .site_switcher {
      @extend .ml-3;

      .language {
        .nav-item {
          .nav-link {
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            color: $white;

            transition: $transition-base;

            &:hover {
              color: $black;
            }

            @include media-breakpoint-up(xl) {
              text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
            }
          }

          .dropdown-menu {
            position: absolute;

            padding: 5px 0;
            @extend .border-0;
            overflow: hidden;

            box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

            .dropdown-item {
              padding: 5px 15px;

              &:hover,
              &:focus {
                background-color: transparent;
                color: $blue;
              }
              &::after {
                transform: rotate(90deg);
                transition: all 0.2s ease;
                .open & {
                  transform: rotate(0);
                }
              }
            }
          }
        }
      }
    }
  }

/* Header-extra
   ========================================================================== */
  .header-extra {
    position: relative;

    .extra-text {
      .container-holder {
        position: absolute;
        right: 20px;
        bottom: -75px;

        max-width: 120px;
        padding: 5px 10px 15px 10px;
        background-color: $secondary;

        font-size: $font-size-base * 0.75;
        @extend .text-center;

        @include media-breakpoint-up(xl) {
          right: 40px;
          max-width: 185px;
          padding: 0;

          font-size: $font-size-base * 0.875;
          p {
            padding: 5px 20px;
          }
        }

        p {
          &:last-of-type {
            @extend .mb-0;
          }
        }

        a {
          font-weight: $font-weight-bold;
          color: $white;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    body:not(.home) & {
      @extend .d-none;
    }
  }

/* Header - Sticky
   ========================================================================== */
  &.sticky {
    @include media-breakpoint-up(xl) {
      position: fixed;

      background-color: $blue;
      // box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    }

    .header-main {
      .logo {
        @include media-breakpoint-up(xl) {
          max-width: 175px;
        }
      }

      .navbar-toggler {
        .navbar-toggler-icon {
          @include media-breakpoint-up(xl) {
            text-shadow: none;
          }
        }
      }

      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            .nav-link {
              @include media-breakpoint-up(xl) {
                text-shadow: none;
              }
            }
          }
        }
      }

      .site_switcher {
        .language {
          .nav-item {
            .nav-link {
              @include media-breakpoint-up(xl) {
                text-shadow: none;
              }
            }
          }
        }
      }
    }
  }

  body:not(.home) & {
    @extend .sticky;
  }
}
