@mixin carousel-height {
  min-height: $eyecatcher-height-sm-small;

  @include media-breakpoint-up(xl) {
    min-height: $eyecatcher-height-small;
  }

  .large & {
    min-height: $eyecatcher-height-sm-big;

    @include media-breakpoint-up(xl) {
      min-height: $eyecatcher-height-big;
    }
  }
}