html, body, h2, h3, h4, h5 {
    font-size: inherit;
}


$tommy_color_primary: $theme-color-primary;
$tommy_color_primary_dark: darken($tommy_color_primary, 0.4);
$tommy_color_secondary: $theme-color-secondary;

/* custom - op basis van TommyBookingSupport */
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav>.tbs-active>a:hover { color: #fff; background-color: $tommy_color_primary; }
#TommyBookingSupport .tbs-btn-info { color: #fff;  background-color: $tommy_color_primary; border-color: $tommy_color_primary; }
#TommyBookingSupport .tbs-btn-info:hover { background-color: $tommy_color_primary_dark; border-color:  $tommy_color_primary_dark; }
#TommyBookingSupport .tbs-btn-success { color: #fff; background-color: $tommy_color_primary; border-color: $tommy_color_primary; margin-bottom: 20px; }
#TommyBookingSupport .tbs-btn-success:hover { color: #fff; background-color: $tommy_color_primary_dark; border-color: $tommy_color_primary_dark; }
#TommyBookingSupport .tbs-btn-info, #TommyBookingSupport .tbs-btn-success { transition: all 0.5s ease; }
#TommyBookingSupport .mapplic-map a.mapplic-pin {filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.8)); }

body #TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected { background-color: $tommy_color_secondary; }


#TommyBookingSupport .tbs-row.tbs_persoonscategorien, #TommyBookingSupport .tbs-row.tbs_accommodaties, #TommyBookingSupport .tbs-row.tbs_kalenders, #TommyBookingSupport .tbs-container-fluid.tbs_kassabon, #TommyBookingSupport .tbs-row.tbs_artikelen, #TommyBookingSupport .tbs-row.tbs_persoon, #TommyBookingSupport .tbs-row .tbs-page-overzicht > .tbs-container-fluid > .tbs-row:nth-child(2) {
    background-color: #f9f9f9 !important;
    padding: 15px;
    border-radius: 5px;
}
#TommyBookingSupport .tbs_kassabon_overzicht h2 { font-size: 16px; }
#TommyBookingSupport .tbs_kassabon_overzicht h2.tbs-totaal { font-size: 20px; }



/* custom - op basis van TommyBookingSupport MINI Widget */
.mini-zeb .tommy-zeb-wrapper * { font-family: 'Cabin',sans-serif !important;}
.mini-zeb .tommy-zeb-wrapper .tommy-zeb-left {
    background: none;
    color: #fff;
    padding: 20px 0!important;
}

.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .ng-scope { display: inline;}
.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .ng-scope:first-child { display: inline;}

.mini-zeb .tommy-zeb-wrapper-mini h2.ng-binding { display: inline;}

.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .ng-scope { padding: 0;}

.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left .tommy-zeb-accommodation-types {
    flex: 1 1 100px;
    max-width: inherit;
}
.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left .tommy-zeb-accommodation-types {
    display: none !important;
    margin-right: 30px;
    padding-right: 20px;

    border-bottom: none;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left .tommy-zeb-accommodation-types .ng-isolate-scope {
    width: 100%;
}

@media (min-width: 1200px) {
    .mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .tommy-zeb-accommodation-person-category-label { line-height: 28px;}
    .mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories select { float: right;}
    .content .tommy-zeb-wrapper .tommy-zeb-result-image img { max-width: 250px; }
    .content .tommy-zeb-wrapper .tommy-zeb-result-details {width: 390px;float: left;}
}
@media (min-width: 980px) and (max-width: 1199px) {
    .mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini * { display: flow-root; }
    .mini-zeb .tommy-zeb-wrapper .tommy-zeb-date-begin { margin-bottom: 0;}
    .content .tommy-zeb-wrapper .tommy-zeb-result-image img { max-width: 180px; }
    .content .tommy-zeb-wrapper .tommy-zeb-result-details {  }
}
.mini-zeb .tommy-zeb-wrapper select option { display: block;}

.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories, 
.mini-zeb .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date { border-right-color: #fff;}

body .tommy-zeb-wrapper .tommy-zeb-left { background: $tommy_color_primary; border: 0; }
body .tommy-zeb-wrapper .tommy-zeb-left h1 {
    color: #fff;
    font-weight: 400;
    line-height: 1;
    font-family: 'Oswald', sans-serif !important;
}
body .tommy-zeb-wrapper * h2 {
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif !important;
    display: none!important;
}
body .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input { padding: 3px 6px;}
body .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding { color: #466228;}
body .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding { color: $tommy_color_primary;}
body .tommy-zeb-wrapper-mini button.tommy-zeb-btn { 
    margin-top: 0!important; 
    background-color: $tommy_color_secondary;
    color: #fff;
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 600;
    font-size: .875rem;
    margin-left: 60px;
    cursor: pointer;
}
body .tommy-zeb-wrapper .tommy-zeb-btn { 
    margin-top: 15px!important; 
    background-color: $tommy_color_secondary;
    color: #fff;
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 600;
    font-size: .875rem;
    cursor: pointer;
}

body .tommy-zeb-wrapper .tommy-zeb-btn:hover {
    background-color: $tommy_color_primary_dark;
    color: #fff;
}
body .tommy-zeb-wrapper .tommy-zeb-btn:hover { background-color: #f4cd29; color: $tommy_color_primary; }