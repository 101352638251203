/*  ==========================================================================
    owl-carousel
	  ========================================================================== */
.owl-carousel {
  .owl-stage {
    @extend .d-flex;
  }

  .owl-item {
    flex: 0 0 auto;
    float: none;
  }

/*  owl-carousel > owl-nav
	  ========================================================================== */
  .owl-nav {
    position: absolute;
    right: 0;
    bottom: 50%;
    left: 0;
    z-index: 50;

    @extend .d-flex;
    @extend .justify-content-between;

    height: 0;
    margin-bottom: 28px;

    button.owl-prev,
    button.owl-next {
      display: block;
      width: 56px;
      height: 56px;
      padding: 0;

      background-color: rgba(255,255,255,.25);
      border: none;
      border-radius: 50%;
      box-shadow: 0 3px 6px 0 #00000029;
      outline: 0;
      cursor: pointer;

      font-size: 1.5rem; /* 24px */
      line-height: 56px;
      text-align: center;
      color: $white;

      transition: $transition-base;

      &:hover {
        background-color: rgba(255,255,255,.5);
      }

      &.disabled {
        @extend .d-none;
      }
    }

    button.owl-prev { margin-left: 15px; }
    button.owl-next {
      margin-right: 15px;
      margin-left: auto;
    }

    &.disabled {
      @extend .d-none;
    }
  }

/*  owl-carousel > owl-dots
    ========================================================================== */
  .owl-dots {
    position: absolute;
    right: 0;
    bottom: 35px;
    left: 0;

    @extend .d-flex;
    @extend .justify-content-center;
    height: 0;

    transition: $transition-base;

    .owl-dot {
      @extend .d-block;
      outline: 0;
      
      span {
        @extend .d-block;
        width: 20px;
        height: 20px;
        margin: 0 2.5px;
        padding: 0;

        background-color: rgba(255,255,255,.25);
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        box-shadow: 0 3px 6px 0 #00000029;
        outline: 0;
        cursor: pointer;

        font-size: .875rem;
        line-height: 18px;

        transition: $transition-base;
      }

      &:hover,
      &.active {
        span {
          background-color: $green;
          border: 1px solid #FFFFFF;
        }
      }
    }

    &.disabled {
      @extend .d-none;
    }
  }
}

.owl-holder {
  @extend .d-flex;
  @extend .align-items-end;
  @extend .h-100;

  .container {
    max-width: 100%;
    @extend .h-100;
    @extend .px-0;
  }
}

.owl-caption {
  @extend .d-flex;
  @extend .flex-column;
  padding: 145px 25px 25px 25px;

  @include media-breakpoint-up(xl) {
    height: 100%;
    width: 100%;
    padding-left: 490px;
    align-items: center;
    justify-content: end;
    padding-bottom: 5rem;
  }

  .owl-title {
    @extend .order-2;
    @extend .tt-banner-title;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up(xl) {
      text-shadow: none;
    }
  }

  .owl-subtitle {
    @extend .order-1;
    @extend .tt-banner-subtitle;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up(xl) {
      text-shadow: none;
    }
  }

  .owl-text {
    @extend .order-3;
    @extend .tt-banner-text;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up(xl) {
      text-shadow: none;
    }
  }

  .owl-btn {
    @extend .order-4;
    // @extend .mr-auto;
    @extend .btn;
    @extend .btn-black;

    &:hover {
      background-color: $white;
      border-color: $white;
      color: $black;
    }
  }
}