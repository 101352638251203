/* ==========================================================================
   Cards
   ========================================================================== */
.card {
  @extend .h-100;
  @extend .border-0;

  /* Card - Image */
  .card-image {
    background-color: #F1F1F1;
    .card-image-link {
      @extend .d-block;
    }

    .card-img-top {
      @extend .rounded-0;
    }
  }

  /* Card - Body */
  .card-body {
    padding: 15px 10px 25px 10px;
    background-color: #F1F1F1;

    word-break: break-word;

    @include media-breakpoint-up(sm) {
      word-break: normal;
    }

    .card-caption {
      @extend .d-flex;
      @extend .flex-column;
      min-height: 100%;
    }

    .card-title-link {
      @extend .order-2;

      .card-title {
        @extend .tt-card-title;
        transition: $transition-base;
      }

      &:hover {
        @extend .tt-card-title--hover;

        .card-title {
          @extend .tt-card-title--hover;
        }
      }
    }

    .card-title {
      @extend .order-2;
      @extend .mb-1;
      @extend .tt-card-title;
    }

    .card-subtitle {
      @extend .order-1;
      @extend .mt-0;
      @extend .mb-1;
      @extend .tt-card-subtitle;
    }

    .card-text {
      @extend .order-3;
      @extend .tt-card-text;

      p {
        @extend .tt-card-text;
      }
    }

    .card-buttons {
      @extend .order-4;
      @extend .mt-auto;
    }
    .card-btn {
      @extend .order-4;

      @extend .btn-link;
      @extend .btn-link--primary;
      @extend .add-arrow--after;
    }
  }

  /* Card - Img-overlay */
  .card-img-overlay {
    background-color: rgba($black, 0.25);
    overflow: hidden;

    .card-caption {
      @extend .d-flex;
      @extend .flex-column;
    }

    .card-title-link {
      @extend .order-2;

      .card-title {
        @extend .tt-card-title;
        transition: $transition-base;
      }

      &:hover {
        @extend .tt-card-title--hover;

        .card-title {
          @extend .tt-card-title--hover;
        }
      }
    }

    .card-title {
      @extend .order-2;
      @extend .mb-1;

      @extend .tt-card-title--white;
    }

    .card-subtitle {
      @extend .order-1;
      @extend .mt-0;
      @extend .mb-1;

      @extend .tt-card-subtitle--white;
    }

    .card-text {
      @extend .d-none;
      @extend .d-sm-block;
      @extend .order-3;

      @extend .tt-card-text--white;

      p {
        @extend .tt-card-text--white;
      }
    }

    .card-buttons {
      @extend .order-4;
    }
    .card-btn {
      @extend .btn;
      @extend .btn-secondary;
    }
  }

/* Card - Wide
   ========================================================================== */
   &.wide {
    @extend .d-flex;
    @extend .flex-row;
    @extend .flex-wrap;

    /* Card - Image */
    .card-image {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(4);
      }
      
      @extend .p-0;
    }

    /* Card - Body */
    .card-body {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(8);
      }

      .card-caption {
        .card-btn {
          @extend .mr-auto; 
        }
      }
    }
  }

/* Card - Arrangement
   ========================================================================== */
  &.card-arrangement {
    transition: $transition-base;

    .card-body {
      @include make-row();
      @extend .mx-0;

      .card-caption {
        flex: 0 0 100%;
        @extend .order-2;
        @extend .order-sm-1;

        min-height: auto;

        @include media-breakpoint-up(sm) {
          flex: 1 1 0px;
          padding-right: 15px;
        }
      }

      .card-rating {
        flex: 0 0 100%;
        @extend .order-1;
        @extend .order-sm-2;

        @include media-breakpoint-up(sm) {
          flex: 0 0 70px;
        }

        .card-stars,
        .rating {
          @extend .d-flex;
          @extend .flex-wrap;
          
          .list-item,
          li {
            font-size: $font-size-base * 0.75;
          }
        }
      }

      .card-properties {
        @extend .order-3;
        @include make-col(12);

        .card-characteristics {
          .list-item {
            position: relative;
            padding-left: 30px;

            @extend .tt-card-text;
            
            &::before {
              @extend .d-none;
            }

            .list-icon {
              position: absolute;
              left: 0;
            }
          }
        }
      }

      .card-traveldata {
        flex: 1 1 auto;
        @extend .order-4;

        @extend .mt-auto;
        padding-top: 35px;
        padding-right: 15px;

        @extend .tt-card-text;

        .card-arrival-label {
          font-weight: $font-weight-normal;
        }
      }

      .card-actions {
        flex: 0 1 auto;
        @extend .order-5;

        @extend .mt-auto;
        padding-top: 35px;

        .card-btn {
          @extend .btn-sm;
          @extend .btn-tertiary;
          @extend .add-arrow--none;

          .card-old-price {
            margin-right: 5px;
            font-size: $font-size-base * 0.625;
            font-weight: $font-weight-light;
            text-decoration: line-through;
          }
        }
      }
    }

    &:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
      transform: scale(1.0125);
    }
  }
}

/* Green-cards > Card
   ========================================================================== */
.green-cards {
  .card {
    @extend .d-flex;
    @extend .flex-row;

    max-height: 100px;
    transition: $transition-base;

    .card-image {
      flex: 0 0 100px;
    }

    .card-body {
      padding: 15px;
      background-color: $green;

      transition: $transition-base;

      .card-caption {
        @extend .d-flex;
        @extend .flex-column;

        @extend .h-100;
        overflow: hidden;
      }

      .card-title {
        @extend .tt-card-green-title;
      }

      .card-subtitle {
        @extend .tt-card-green-subtitle;
      }

      .card-text {
        @extend .tt-card-green-text;

        p {
          @extend .tt-card-green-text;
        }
      }
    }
  }

  a.card {
    &:hover {
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
      transform: scale(1.0125);

      text-decoration: none;

      .card-body {
        background-color: $blue;
      }
    }
  }
}

/* Inspiration > Card
   ========================================================================== */
.inspiration {
  .card {
    .card-img-overlay {
      @extend .d-flex;
      @extend .align-items-end;
      
      padding-top: 30px;
      padding-bottom: 30px;
      
      @extend .px-0;
      @extend .px-sm-4;

      @include media-breakpoint-up(sm) {
        padding-top: 60px;
        padding-bottom: 60px;
      }

      .card-caption {
        @extend .container;
      }

      .card-title {
        @extend .tt-card-inspiration-title;
      }

      .card-subtitle {
        @extend .tt-card-inspiration-subtitle;
      }

      .card-text {
        @extend .tt-card-inspiration-text;

        p {
          @extend .tt-card-inspiration-text;
        }
      }
    }
  }

}