.default {
	&.home {
		.section_one {
			padding-top: 55px;
			@extend .px-sm-4;
			
			.container-two_columns {
				@extend .py-3;
			}
		}

		.section_two {
			padding-top: 60px;
			@extend .px-sm-4;

			.link {
				@extend .btn;
				@extend .btn-secondary;
				margin-top: 30px;
			}
		}

		.section_three {
			padding-top: 60px;
			padding-bottom: 60px;

			@extend .px-sm-4;
		}

		.section_four {
			padding: 30px 0;
			@extend .px-sm-4;

			h2 {
				font-size: $font-size-base * 1.5;

				@include media-breakpoint-up(md) {
					font-size: $font-size-base * 2.25;
				}
			}

			h3 {
				font-size: $font-size-base * 0.75;
				text-transform: uppercase;

				@include media-breakpoint-up(md) {
					font-size: $font-size-base * 1.125;
				}
			}
		}

		.section_five {}

		.section_six {
			padding: 60px 0;
			@extend .px-sm-4;
		}

		.footer {
			@extend .pb-0;
		}
	}
}
