/* ==========================================================================
   Eyecatcher
   ========================================================================== */
.eyecatcher {
	position: relative;
	min-height: $eyecatcher-height-sm-small;
	background-color: #EEEEEE;
	overflow: hidden;

	@include media-breakpoint-up(xl) {
		min-height: $eyecatcher-height-small;
	}
  
	.container-default {
		@extend .px-0;
	}

	.item {
		min-height: $eyecatcher-height-sm-small;
    height: 100%;

    background-position: center center;
    background-repeat: no-repeat;
		background-size: cover;
		
		@include media-breakpoint-up(xl) {
			min-height: $eyecatcher-height-small;
		}
	}
	
	.owl-caption {
		@extend .d-none;
	}

/*  owl-carousel > owl-nav
	  ========================================================================== */
	.owl-nav {
		bottom: 45px;

		button.owl-prev { margin-left: -60px; }
		button.owl-next { margin-right: -60px; }
	}

/*  owl-carousel - owl-dots
    ========================================================================== */
	.owl-dots {
		bottom: -60px;
	}

	&:hover {
		.owl-nav {
			button.owl-prev { margin-left: 15px; }
			button.owl-next { margin-right: 15px; }
		}

		.owl-dots { bottom: 35px; }
	}
	
	&::before {
		position: absolute;
		top: 50%;
		left: 50%;

		display: block;
		margin-top: -25px;
		margin-left: -25px;

		font-family: "Font Awesome 5 Pro";
		font-size: 50px;
		line-height: 50px;
		color: #BBBBBB;

		content: "\f1ce";

		animation: fa-spin 2s infinite linear;
	}
	
/*  eyecatcher > large
	  ========================================================================== */
	&.large {
		min-height: $eyecatcher-height-sm-big;

		@include media-breakpoint-up(xl) {
			min-height: $eyecatcher-height-big;
		}

		.item {
			min-height: $eyecatcher-height-sm-big;

			@include media-breakpoint-up(xl) {
				min-height: $eyecatcher-height-big;
			}
		}
	}
	
/*  eyecatcher > home
	  ========================================================================== */
	.home & {
		.owl-caption {
			@extend .d-flex;
		}
		

		.owl-nav {
			@include media-breakpoint-up(xl) {
				left: inherit;
				width: calc(100% - 490px);
			}
		}

		.owl-dots {
			@include media-breakpoint-up(xl) {
				left: inherit;
				width: calc(100% - 490px);
			}
		}
	}
}