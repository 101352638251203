.default {
	&.landings_page_sub {


		.btn {
			@extend .btn-primary;
		}
		
		.section_intro,
		.section_offer,
		.section_aanbod  {
			@extend .px-3;
			@extend .px-sm-4;
			@extend .py-3;
			@extend .py-sm-5;
		}
		.section_title {
			@extend .px-3;
			@extend .px-sm-4;
			@extend .pt-3;
			@extend .pt-sm-5;
			@extend .pb-2;
			@extend .pb-sm-4;

			@extend .bg-secondary;
			color: $white;
		}
		.section_offer {
			@extend .bg-secondary;
			color: $white;
		}

		.section_content_field {
			@extend .p-0;
			.container-holder {
				.column {
					@extend .p-0;
				}
			}
		
			.content_left {
				.column.one {
					@extend .p-4;
					@include media-breakpoint-up(md) {
						padding: 5% 50px 30px 10%!important;
					}
				}
			}
			.content_right {
				.column.two {
					@extend .p-4;
					@include media-breakpoint-up(md) {
						padding: 5% 10% 30px 50px!important;
					}
				}
			}

			.resource_image {
				img {
					width: 100%;
					height: auto;
				}
			}
			
		}


	}
}
