.gallery {
  .gallery-holder {
    @include make-row();
    margin: 0 -10px -20px -10px;

    .highlight {
      @include make-col-ready();
      @include make-col(12);

      margin-bottom: 20px;
      padding: 0 10px;

      transition: $transition-base;

      a { @extend .d-block; }
      img { width: 100%; }

      &:hover {
        transform: scale(1.025);
      }
    }

    .item {
      @include make-col-ready();
      @include make-col(6);

      margin-bottom: 20px;
      padding: 0 10px;

      transition: $transition-base;

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      &.extra-thumbs {
        a {
          position: relative;
          @extend .d-block;
        }

        .remaining {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          z-index: 10;

          @extend .d-flex;
          @extend .justify-content-center;
          @extend .align-items-center;

          width: 100%;
          background: rgba($black,.62);

          font-size: 30px;
          color: $white;

          @include media-breakpoint-up(md) {
            font-size: 50px;
          }

          @include media-breakpoint-up(lg) {
            font-size: 30px;
          }

          @include media-breakpoint-up(xl) {
            font-size: 50px;
          }
          
          &::before {
            content: '+';
          }
        }

        &.first {
          .remaining {
            @extend .d-none;
          }
        }

        &.before-last {
          .remaining {
            @extend .d-flex;
            @extend .d-lg-none;
          }
        }

        &.last {
          @extend .d-none;
          @extend .d-lg-block;
        }
      }

      &:hover {
        transform: scale(1.025);
      }
    }
  }
}