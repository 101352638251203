/*==========================================================================
  Footer
  ========================================================================== */

.footer {
  padding-bottom: 85px;
  @extend .text-left;
  @extend .text-sm-center;

  .container {
    @include media-breakpoint-up(md) {
      max-width: 600px;
    }
  }

/*Footer-top
  ========================================================================== */
  .footer-top {
    padding-top: 50px;

    @extend .bg-primary;

    h4 {
      @extend .tt-footer-title;
    }
  }

/* Footer-middle
  ========================================================================== */
  .footer-middle {
    padding-top: 60px;
    padding-bottom: 70px;

    @extend .bg-primary;

    .container-three-columns {
      .column.one,
      .column.two,
      .column.three {
        flex: 1 1 auto;
        width: auto;
      }
    }

    h5 {
      @extend .tt-footer-subtitle;
    }
  }

/* Footer-bottom
  ========================================================================== */
  .footer-bottom {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .footer_logolink {
    margin-top: 20px;

    .list {
      @include make-row();

      @extend .mb-n3;

      .list-item {
        @include make-col-ready();
        @include make-col(12);
        max-width: 290px;

        @extend .mb-3;

        @include media-breakpoint-up(sm) {
          @include make-col(6);
        }

        .link {
          position: relative;
          @extend .d-flex;
          @extend .align-items-center;
          min-height: 53px;
          padding-right: 10px;
          padding-left: 60px;

          background-color: $white;
          border-radius: 3px;
          box-shadow: 0 0 0 0 $white;
          word-break: break-all;

          transition: $transition-base;

          .link-icon {
            position: absolute;
            top: 5px;
            left: 5px;

            width: 43px;
            height: 43px;
            background-color: $black;
            border-radius: 50%;

            line-height: 43px;
            @extend .text-center;
            color: $white;

            transition: $transition-base;
          }

          .link-text {
            flex: 1 1 auto;
            font-size: $font-size-base * 0.875;
            font-weight: $font-weight-bold;
            line-height: 20px;
            @extend .text-left;
            color: $black;
          }

          &:hover {
            background-color: $white;
            box-shadow: 0 0 0 0.2rem rgba($white,.25);

            text-decoration: none;
            color: $white;

            .link-icon {
              background-color: $white;
              color: $black;
            }
          }
        }
      }
    }
  }

  .footer_list {
    .list {
      .list-item {
        @extend .tt-footer-list;

        .link {
          @extend .tt-footer-list;

          &:hover {
            @extend .tt-footer-list--hover;
          }
        }
      }
    }
  }

  .footer_link {
    .list {
      @extend .d-flex;
      @extend .flex-wrap;
      @extend .justify-content-center;

      .list-item {
        @extend .mx-3;
        @extend .tt-footer-menu;

        .link {
          @extend .tt-footer-menu;

          &:hover {
            @extend .tt-footer-menu--hover;
          }
        }
      }
    }
  }
}