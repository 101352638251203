/* ==========================================================================
   Base
   ========================================================================== */

/* Body
   ========================================================================== */
body.default:not(.home) {
  @include media-breakpoint-up(xl) {
    padding-top: 150px;
    transition: $transition-base;
  }

  &.sticky {
    @include media-breakpoint-up(xl) {
      padding-top: 70px;
    }
  }

  &.location_overview,
  &.accommodation_overview,
  &.location_accommodation_overview,
  &.accommodation_arrangement_overview,
  &.accommodation_search_book,
  &.accommodation_detail,
	&.location_detail,
  &.location_accommodation_detail,
  &.accommodation_book {
    @include media-breakpoint-up(xl) {
      padding-top: 70px;
    }
  }
}

.container,
.container-fluid {
  padding-right: 20px;
  padding-left: 20px;

  @include media-breakpoint-up(sm) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.container {
  @include media-breakpoint-up(md) {
    max-width: 1440px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1440px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1440px;
  }
}


/* Main
   ========================================================================== */
.main {
  /* Headlines */
  h1 {
    @extend .tt-content-h1;
  }
  h2 {
    @extend .tt-content-h2;
  }
  h3 {
    @extend .tt-content-h3;
  }
  h4 {
    @extend .tt-content-h4;
  }
  h5 {
    @extend .tt-content-h5;
  }
  h6 {
    @extend .tt-content-h6;
  }

  /* Paragraph */
  p {
    @extend .tt-content-text;
  }

  /* List */
  ul,
  ol {
    li {
      @extend .tt-content-text;
    }
  }

  /* List > custom */
  .list-custom {
    @extend .list;

    li {
      position: relative;
      @extend .tt-content-list;

      padding-left: 30px;

      &::before {
        position: absolute;
        left: 0;

        font-family: "Font Awesome 5 Pro";
        font-size: $font-size-base * 1.125;
        font-weight: $font-weight-normal;
        color: $secondary;

        content: "\f058";
      } 
    }
  }

  /* List 
  ul,
  ol {
    padding-left: 0;
    list-style: none;

    li {
      position: relative;
      @extend .tt-content-list;

      padding-left: 30px;

      &::before {
        position: absolute;
        left: 0;

        font-family: "Font Awesome 5 Pro";
        font-size: $font-size-base * 1.125;
        font-weight: $font-weight-normal;
        color: $secondary;

        content: "\f058";
      } 

      a {}
    }
  }*/
}