.page_block {
  &.grid {
    @include make-row();
    
    margin-bottom: -20px;

    .item {
      @include make-col-ready();
      @include make-col(12);

      margin-bottom: 20px;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(3);
      }
    }
  }

  &.card-columns {
    column-count: 1;
    column-gap: 30px;

    @include media-breakpoint-up(sm) {
      column-count: 2;
    }

    @include media-breakpoint-up(md) {
      column-count: 2;
    }

    @include media-breakpoint-up(lg) {
      column-count: 3;
    }

    @include media-breakpoint-up(lg) {
      column-count: 4;
    }

    .item {
      display: inline-block;
      width: 100%;
    }

    .card {
      display: inherit;
      width: inherit;
      margin-bottom: 20px;
    }
  }

  &.inspiration {
    @include make-row();

    @extend .mx-0;
    
    .item {
      @include make-col-ready();
      @include make-col(12);

      @extend .px-0;

      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }

      @include media-breakpoint-up(md) {
        @include make-col(6);
      }

      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
      
      @include media-breakpoint-up(xl) {
        @include make-col(6);
      }

      &:first-of-type {
        @include make-col(12);
      }
    }
  }
}