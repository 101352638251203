.mini-sab {
  position: relative;
  z-index: 100;

  padding: 20px;
  background-color: $blue;

  @include media-breakpoint-up(xl) {
    position: fixed;
    right: 0;
    bottom: 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    body.home & {
      top: 0;
      box-shadow: none;
    }
    left: 0;

    padding: 0 40px 25px 40px;
  }
  
  .container-two-columns {
    .column.one {
      @extend .px-3;

      @include media-breakpoint-up(md) {
        @include make-col(12);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(3);
        
      }
    }

    .column.two {
      @extend .px-3;

      @include media-breakpoint-up(md) {
        @include make-col(12);
      }

      @include media-breakpoint-up(xl) {
        body.home & {
          @include make-col(12);
        }
      }
    }
  }

  .home & {
    .container-two-columns {
      @extend .container-fluid;
      
      .column.two {
        @extend .p-0;
      }
    }
    
    @include media-breakpoint-up(xl) {
      position: relative;
      max-width: 490px;
      min-height: 330px;
      margin-top: -810px;
      &::before {
          content: ("");
          display: block;
          min-height: 405px;
        }
    }
  }

  .default:not(.home) & {
    @include media-breakpoint-up(xl) {
      padding: 25px 40px;
    }

    .container-two-columns {
      @extend .p-0;
    }
  }

  .location_overview &,
  .accommodation_overview &,
  .location_accommodation_overview &,
  .accommodation_arrangement_overview &,
  .accommodation_search_book &,
  .accommodation_detail &,
	.location_detail &,
  .location_accommodation_detail &,
  .accommodation_book & {
    @extend .d-none;
  }
}