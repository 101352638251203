.btn-wide {
  min-width: 240px;
}

.btn-link {
  outline: none;
  font-size: $btn-font-size;
  text-decoration: none;
  
  &--primary {
    color: $primary;

    &:hover {
      text-decoration: none;
      color: $black;
    }
  }

  &--white {
    color: $white;

    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }
}

.btn-back {
  @extend .mb-3;
  @extend .btn-link;
  @extend .btn-link--primary;
  @extend .add-arrow--before;
  @extend .add-arrow--before-r;
}


.btn-primary {
  &:hover {
    background-color: $black;
    border-color: $black;
    color: $white;
  }
}

.btn-secondary {
  color: $white;

  &:hover {
    background-color: $black;
    border-color: $black;
    color: $white;
  }
}
.btn-outline-secondary {
  &:hover {
    color: $white;
  }
}

.btn-tertiary {
  &:hover {
    background-color: $black;
    border-color: $black;

    color: $white;
  }
}

.link {
  .bg-secondary & {
    @extend .btn;
    @extend .btn-tertiary;
  }
}