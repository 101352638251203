/* ==========================================================================
   Bootstrap-carousel
   ========================================================================== */

.carousel {
  /* Carousel - Normal */
  .carousel-item {
    @include carousel-height();
    
    .carousel-holder {
      @include carousel-height();

      .carousel-caption {
        @extend .text-left;
      }
    }
  }

  /* Carousel - Background */
  .carousel-item-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .carousel-holder {
      @extend .d-flex;
      @extend .align-items-center;

      .carousel-caption {
        position: relative;
        right: unset;
        bottom: unset;
        left: unset;
      }
    }
  }
}