.default {
	&.assortiment_overview {
		.section_one {
			@extend .py-4;

			@extend .px-sm-4;
			@extend .py-sm-5;
		}
	}

	&.assortiment_detail {
		.section_one {
			@extend .py-4;

			@extend .px-sm-4;
			@extend .py-sm-5;
		}

		.assortiment_detail {
			@include make-row();

			.info {
				@include make-col-ready();
				@include make-col(12);

				@extend .mb-4;
				@extend .pr-lg-5;

				@extend .tt-content-text;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}
			}

			.content {
				@include make-col-ready();
				@include make-col(12);

				@extend .mb-4;
				@extend .pl-lg-5;

				@extend .tt-content-text;

				@include media-breakpoint-up(lg) {
					@include make-col(6);
				}
			}
		}
	}
}
