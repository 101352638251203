/* ==========================================================================
   Typography
   ========================================================================== */

/* Menu
   ========================================================================== */
.tt-menu-toggler {
  color: $white;

  &--hover {
    color: $black;
  }
}

.tt-menu-main {
  font-weight: $font-weight-normal;
  text-transform: uppercase;
  color: $white;

  &--hover,
  &--active {
    text-decoration: underline;
  }

  &--last {}
}

.tt-menu-sub {
  font-weight: $font-weight-light;
  text-transform: unset;

  &--hover,
  &--active {}
  &--last {}
}

.tt-menu-language {
  &--hover,
  &--active {}
}


/* Content
   ========================================================================== */
.tt-content-h1 {
  margin-bottom: 25px;
  font-size: $font-size-base * 1.5;

  .bg-primary &,
  .bg-secondary &,
  .bg-tertiary & {
    color: $white;
  }
}
.tt-content-h2 {
  margin-bottom: 25px;
  font-size: $font-size-base * 1.125;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 1.5;
  }

  .bg-primary &,
  .bg-secondary &,
  .bg-tertiary & {
    color: $white;
  }
}
.tt-content-h3 {
  .bg-primary &,
  .bg-secondary &,
  .bg-tertiary & {
    color: $white;
  }
}
.tt-content-h4 {
  .bg-primary &,
  .bg-secondary &,
  .bg-tertiary & {
    color: $white;
  }
}
.tt-content-h5 {
  .bg-primary &,
  .bg-secondary &,
  .bg-tertiary & {
    color: $white;
  }
}
.tt-content-h6 {
  .bg-primary &,
  .bg-secondary &,
  .bg-tertiary & {
    color: $white;
  }
}

.tt-content-text {
  font-size: $font-size-base * 0.75;
  font-weight: $font-weight-light;
  line-height: 24px;

  .bg-primary &,
  .bg-secondary &,
  .bg-tertiary & {
    color: $white;
  }
}

.tt-content-list {
  font-size: $font-size-base * 0.875;
  font-weight: $font-weight-normal;
  line-height: 24px;

  .bg-primary &,
  .bg-secondary &,
  .bg-tertiary & {
    color: $white;
  }
}

/* Banner
   ========================================================================== */
.tt-banner-title {
  font-size: $font-size-base * 1.5;
  color: $white;
}
.tt-banner-subtitle {
  font-size: $font-size-base * 0.75;
  text-transform: uppercase;
  color: $white;
}
.tt-banner-text {
  font-weight: $font-weight-light;
  color: $white;
}


/* Card
   ========================================================================== */
.tt-card-title {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $black;

  &--hover {
    text-decoration: none;
    color: $primary;
  }

  &--white {
    @extend .tt-card-title;
    color: $white;
  }
}

.tt-card-subtitle {
  font-size: $font-size-base * 0.75;
  font-weight: $font-weight-light;
  color: $black;

  &--white {
    @extend .tt-card-subtitle;
    color: $white;
  }
}

.tt-card-text {
  font-size: $font-size-base * 0.75;
  line-height: 18px;
  color: $black;

  &--white {
    @extend .tt-card-text;
    color: $white;
  }
}

/* Card - Green
   ========================================================================== */
.tt-card-green-title {
  font-size: $font-size-base * 0.875;
  line-height: 18px;
  color: $white;
}

.tt-card-green-subtitle {
  font-size: $font-size-base * 0.75;
  font-weight: $font-weight-light;
  color: $white;
}

.tt-card-green-text {
  font-size: $font-size-base * 0.875;
  line-height: 15px;
  color: $black;
}

/* Card - Inspiration
   ========================================================================== */
.tt-card-inspiration-title {
  font-size: $font-size-base * 1.5;
  font-weight: $font-weight-normal;
  color: $white;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 2.25;
  }
}

.tt-card-inspiration-subtitle {
  font-size: $font-size-base * 0.75;
  font-weight: $font-weight-normal;
  color: $white;
  
  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 1.125;
  }
}

.tt-card-inspiration-text {
  font-size: $font-size-base;
  color: $white;
}

/* Footer
   ========================================================================== */
.tt-footer-title {
  font-size: $font-size-base * 1.125;
  line-height: 22px;
  color: $white;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 1.5;
    line-height: 30px;
  }
}

.tt-footer-subtitle {
  font-size: $font-size-base * 0.875;
  line-height: 18px;
  color: $white;
}

.tt-footer-list {
  font-size: $font-size-base * 0.75;
  font-weight: $font-weight-light;
  line-height: 26px;
  color: $white;

  &--hover {
    text-decoration: underline;
  }
}

.tt-footer-menu {
  font-size: $font-size-base * 0.75;
  font-weight: $font-weight-light;
  line-height: 20px;
  color: $black;

  &--hover {
    text-decoration: underline;
  }
}