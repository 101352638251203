/* ==========================================================================
	 Containers
	 ========================================================================== */
	 
/* Container-navbar
   ========================================================================== */
.container-navbar {
	.container-holder {}

	.column.default {}

	.column.navbar {
		@extend .flex-wrap;
	}
}


/* Container-default
   ========================================================================== */
.container-default {
	.container-holder {}
}


/* Container-one-column
   ========================================================================== */
.container-one-column {
	.container-holder {
		@include make_row();
	}

	.column_default {
		@include make-col-ready();
		@include make-col(12);
	}
}


/* Container-two-columns
   ========================================================================== */
.container-two-columns {
	.container-holder {
		@include make-row();

		@extend .mb-n3;
	}

	.column.one,
	.column.two {
		@include make-col-ready();
		@include make-col(12);

		@extend .mb-3;

		@include media-breakpoint-up(md) {
			@include make-col(6);
		}
	}
	.column.one {
		@extend .pr-3;
		@extend .pr-md-4;
		@extend .pr-lg-5;
	}
	.column.two {
		@extend .pl-3;
		@extend .pl-md-4;
		@extend .pl-lg-5;
	}
}


/* Container-three-columns
   ========================================================================== */
.container-three-columns {
	.container-holder {
		@include make-row();

		@extend .mb-n3;
	}

	.column.one,
	.column.two,
	.column.three {
		@include make-col-ready();
		@include make-col(12);

		@extend .mb-3;

		@include media-breakpoint-up(md) {
			@include make-col(4);
		}
	}
}


/* Container-four-columns
   ========================================================================== */
.container-four-columns {
	.container-holder {
		@include make-row();

		@extend .mb-n3;
	}

	.column.one,
	.column.two,
	.column.three,
	.column.four {
		@include make-col-ready();
		@include make-col(12);

		@extend .mb-3;

		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}
		
		@include media-breakpoint-up(lg) {
			@include make-col(3);
		}
	}
}


/* Container-six-columns
   ========================================================================== */
.container-six-columns {
	.container-holder {
		@include make-row();

		@extend .mb-n3;
	}

	.column.one,
	.column.two,
	.column.three,
	.column.four,
	.column.five,
	.column.six {
		@include make-col-ready();
		@include make-col(12);

		@extend .mb-3;

		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}

		@include media-breakpoint-up(md) {
			@include make-col(4);
		}

		@include media-breakpoint-up(xl) {
			@include make-col(2);
		}
	}
}