/*  ==========================================================================
    ALL WIDGETS
    ========================================================================== */

    body wm-mini-search-book-widget .wm-mini-search-book {
        place-content: start!important;
    }
    body.home body wm-mini-search-book-widget .wm-mini-search-book {
        margin-top: 40px;
    }

    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .btn-link,
    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .btn-link:focus {
        color: $body-color;
    }

    body wm-mini-search-book-widget .wm-container .wm-title,
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-button.changed,
    body wm-mini-search-book-widget .wm-active-travel-group p,
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-button.changed .wm-active-travel-group p,
    body wm-mini-search-book-widget .wm-container wm-date-selection .wm-date-picker-button.changed .wm-selected-date p,
    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .btn-link:hover, 

    body wm-search-book-widget .wm-container .wm-title,
    body wm-search-book-widget .wm-container .wm-travel-group-button.changed,
    body wm-search-book-widget .wm-active-travel-group p,
    body wm-search-book-widget .wm-container .wm-travel-group-button.changed .wm-active-travel-group p,
    body wm-search-book-widget .wm-container .wm-date-picker-button.changed .wm-selected-date p,
    body wm-search-book-widget .wm-container .wm-travel-group-popover .wm-travel-group-popover-content .wm-travel-group-value,
    body wm-search-book-widget .wm-container .wm-type-selection-button.changed .wm-selected-type, 

    body wm-matrix-widget .wm-selection-row .travel-group-selection .wm-title, 
    body wm-matrix-widget .wm-selection-row .wm-date-selection .wm-title,
    body wm-matrix .wm-selection-row .travel-group-selection .wm-title,
    body wm-matrix .wm-selection-row .wm-date-selection .wm-title,
    body wm-matrix .wm-selection-row .wm-date-selection .wm-date-picker-button.changed .wm-selected-month,
    body wm-matrix .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-travel-group-value
    {
        color: $theme-color-primary;
    }

    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .travel-group-selection, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-country-selection, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-date-selection, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-filters, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-park-selection, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-period-selection, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-type-selection {
        border: 1px solid #000!important;
    }


    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-travel-group-value,
    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-travel-group-value,
    body wm-no-items-available h2 {
        color: $theme-color-primary;
    }

    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-minus i,
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-minus svg,
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-plus i,
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-plus svg,
    body wm-search-book-widget .wm-results-list .fa-exclamation-triangle,
    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-minus i,
    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-minus svg,
    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-plus i,
    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-popover .wm-travel-group-popover-content .wm-plus svg,
    body wm-no-items-available .fa-exclamation-triangle
    {
        color: $theme-color-primary!important;
    }


    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day.range:not(.faded),
    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day:hover:not(.disabled),
    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day:not(.disabled).focused,
    body wm-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day.range:not(.faded),
    body wm-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day:hover:not(.disabled),
    body wm-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day:not(.disabled).focused.arrow::before
    {
        background-color: $theme-color-tertiary!important;
    }

    body wm-search-book-widget .wm-container .wm-btn.wm-btn-primary,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search
    {
        background-color: $theme-color-secondary!important;
    }

    body wm-mini-search-book-widget .wm-container .wm-btn.wm-btn-search:hover,
    body wm-search-book-widget .wm-container .wm-btn.wm-btn-search:hover,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search:hover,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search:focus 
    {
        background-color: rgba($theme-color-secondary, 0.7)!important;
    }
    body wm-mini-search-book-widget .wm-container .btn.wm-btn,
    body wm-search-book-widget .wm-container .btn.wm-btn {
        border: none!important;
    }

    body wm-mini-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day.faded,
    body wm-search-book-widget .wm-container .wm-date-picker-theme .ngb-dp-day .custom-day.faded,
    body wm-matrix .wm-selection-row .wm-date-selection-item.selected, 
    body wm-matrix .wm-selection-row .wm-date-selection-item:hover
    {
        background-color: rgba($theme-color-tertiary, 0.25)!important;
    }

    body wm-search-book-widget .wm-container .btn-group-toggle .btn
    {
        background-color: rgba($theme-color-primary, 0.5)!important;
        padding: 5px 15px;
        border: none!important;
    }
    body wm-search-book-widget .wm-container .btn-group-toggle .btn.active,
    body wm-search-book-widget .wm-container .btn-group-toggle .btn:hover
    {
        background-color: rgba($theme-color-primary, 1)!important;
    }
    body wm-matrix .wm-container .wm-btn.wm-btn-search {
        background: #261d3a;
        border: none!important;
        box-shadow: none;
        height: inherit;
        border-radius: 0;
    }
    body .wm-container .wm-btn.focus, 
    body wm-container .wm-btn:focus {
        box-shadow: none;
    }

    // OTHER SETTINGS THAN COLORS
    body wm-search-book-widget .wm-search-book .checkbox-list ul li
    {
        width: auto!important;
        margin-right: 60px;
    }
    body wm-search-book-widget .xwm-type-selection-button p.wm-title {
        display: none;
    }
    
    body wm-mini-search-book-widget .wm-mini-search-book .wm-place-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-type-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .travel-group-selection, 
    body wm-mini-search-book-widget .wm-mini-search-book .wm-country-selection, 
    body wm-mini-search-book-widget .wm-mini-search-book .wm-date-selection, 
    body wm-mini-search-book-widget .wm-mini-search-book .wm-filters, 
    body wm-mini-search-book-widget .wm-mini-search-book .wm-park-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-period-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-type-selection {
        height: 45px;
        padding: 0;
        margin: 5px 0;
        border-radius: 3px!important;
        border: none;
    }

    // icons
    body wm-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-button,
    body wm-search-book-widget .wm-container wm-date-selection .wm-date-picker-button,
    body wm-matrix .wm-container wm-travel-group-selection .wm-travel-group-button,
    body wm-matrix .wm-container wm-date-selection .wm-date-picker-button,
    body wm-matrix .wm-container wm-matrix-filter .wm-date-selection .wm-date-picker-button {
        position: relative;
    }


    // flex positions
    
    body wm-mini-search-book-widget .wm-mini-search-book .wm-type-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-place-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .travel-group-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-date-selection,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-filters,
    body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search {
        border-radius: 3px!important;
        margin-right: 15px;
    }
    body.home wm-mini-search-book-widget .wm-mini-search-book .wm-type-selection,
    body.home wm-mini-search-book-widget .wm-mini-search-book .wm-place-selection,
    body.home wm-mini-search-book-widget .wm-mini-search-book .travel-group-selection,
    body.home wm-mini-search-book-widget .wm-mini-search-book .wm-date-selection,
    body.home wm-mini-search-book-widget .wm-mini-search-book .wm-filters,
    body.home wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search {
        flex: 1 1 100%!important;
        max-width: 100%!important; 
        margin-right: 0;
    }
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-no-image {
        align-items: center!important;
    }


    @media only screen and (max-width: 768px) {
        body wm-mini-search-book-widget .wm-mini-search-book .wm-type-selection,
        body wm-mini-search-book-widget .wm-mini-search-book .wm-place-selection,
        body wm-mini-search-book-widget .wm-mini-search-book .travel-group-selection,
        body wm-mini-search-book-widget .wm-mini-search-book .wm-date-selection,
        body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search,
        body wm-mini-search-book-widget .wm-mini-search-book .wm-filters {
            flex: 1 1 100%!important;
            max-width: 100%!important; 
        }
        body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search {
            width: 100%;
            border: none!important;
        }
       
    }
    @media only screen and (max-width: 639px) {
        body wm-search-book-widget .wm-search-book .checkbox-list ul li
        {
            width: 90px!important;
            margin-right: 0;
            margin-bottom: 30px;
            text-align: center;
        }
    }
    @media only screen and (max-width: 576px) {
       
        body wm-mini-search-book-widget .wm-mini-search-book .wm-type-selection,
        body wm-mini-search-book-widget .wm-mini-search-book .wm-place-selection,
        body wm-mini-search-book-widget .wm-mini-search-book .travel-group-selection,
        body wm-mini-search-book-widget .wm-mini-search-book .wm-date-selection,
        body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search {
            flex: 1 1 100%!important;
            max-width: 100%!important; 
        }
    }

/*  ==========================================================================
    RESULTS
    ========================================================================== */

    body wm-search-book-widget .wm-container p 
    {
        color: $zeb-color-secondary;
    }

    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-result-item-content .wm-result-item-title,
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-full-price p:hover
    {
        color: $zeb-color-tertiary;
    }
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-result-item-content .wm-result-item-subtitle
    {
        color: $zeb-color-primary;
    }

    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .showmatrix:hover,
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-btn-book:hover,
    body .wm-container .wm-btn.wm-btn-search:hover {
        background-color: $zeb-color-tertiary;
    }

    body wm-search-book-widget .wm-container wm-matrix-accommodation-view .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item,
    body wm-matrix-widget .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item,
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item,
    body wm-search-book-widget .wm-container wm-stay-selection .wm-type-selection-popover .wm-type-selection-popover-content .wm-type-selection-item:hover,
    body wm-matrix-widget .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.no-availability:hover,
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.no-availability:hover
    {
        background-color: rgba($zeb-color-tertiary,0.1);
    }
    body wm-search-book-widget .wm-container wm-matrix-accommodation-view .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.current,
    body wm-search-book-widget .wm-container wm-matrix-accommodation-view .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item:hover,
    body wm-search-book-widget .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .showmatrix,
    body wm-search-book-widget .wm-container .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-name,
    body wm-search-book-widget .wm-container wm-matrix .wm-matrix-month-posiblities .wm-matrix-period-list button,
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-name,
    body wm-matrix .wm-matrix-month-posiblities .wm-matrix-period-list button
    {
        background-color: rgba($zeb-color-primary,1);
        box-shadow: none;
    }
    body wm-matrix-widget .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.has-availability,
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.has-availability
    {
        background-color: rgba($zeb-color-tertiary,1);
    }

    body wm-matrix-widget .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.has-availability:hover,
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.has-availability:hover
    {
        background-color: $zeb-color-primary;
    }
    body wm-matrix .wm-matrix-object-list .wm-matrix-object-item .wm-matrix-object-price-list .wm-matrix-object-price-list-item.arrangement :after {
        border-right-color: $zeb-color-primary;
        border-top-color: $zeb-color-primary;
    }

    body .wm-container wm-results .wm-results-list .wm-results-item,
    body .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content
    {
        
    }

    body .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-btn-book {
        @extend .btn;
        @extend .btn-primary;
    }
    body .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .more-info {
        @extend .btn;
        @extend .btn-link;
        @extend .px-0;
    }
    body .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-btn-book:hover {
        background: $zeb-color-tertiary;
    }
    body .wm-container wm-results .wm-results-list .wm-results-item .wm-card-content .wm-result-item-footer {
        margin-top: 15px;
    }

/*  ==========================================================================
    MATRIX
    ========================================================================== */

    body wm-matrix-widget .wm-matrix-month-posiblities .wm-matrix-period-list button,
    body wm-matrix .wm-matrix-month-posiblities .wm-matrix-period-list button
    {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    body .spinner .double-bounce2 {
        background-color: $zeb-color-tertiary!important;
    } 

/*  ==========================================================================
    OTHER CHANGES
    ========================================================================== */

    body .wm-container .wm-title,
    body .wm-search-book .wm-sb-filter-search .wm-title {
        text-align: left;
        font-weight: bold;
        line-height: 1.2rem!important;
        margin-top: 5px;
    }
    body wm-mini-search-book-widget .wm-container wm-travel-group-selection .wm-travel-group-button .wm-active-travel-group p,
    body wm-mini-search-book-widget .wm-container wm-date-selection .wm-date-picker-button .wm-selected-date p
    { 
    }
    body .wm-container wm-travel-group-selection .wm-travel-group-button .wm-active-travel-group p,
    body .wm-container wm-date-selection .wm-date-picker-button .wm-selected-date p,
    body .wm-container  .wm-selection-row .travel-group-selection .wm-date-picker-button .wm-selected-month, 
    body .wm-container  .wm-selection-row .wm-date-selection .wm-date-picker-button .wm-selected-month,
    body .wm-container wm-filters-selection .wm-filter-selection-button .wm-selected-filter
    { 
        font-weight: 400;
        font-size: 0.75rem;
    }
    
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .travel-group-selection, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-country-selection, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-date-selection, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-filters, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-park-selection, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-period-selection, 
    body wm-search-book-widget .wm-search-book .wm-sb-filter-search .wm-type-selection {
        background: #fff;
        cursor: pointer;
    }
    body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search {
        flex: inherit!important;
        height: 45px;
        margin: 0;
        font-size: 0.8rem;
        text-transform: initial;
    }
   
    body .wm-container .btn.wm-minus, 
    body .wm-container .btn.wm-plus {
        border: none!important;
        height: 30px;
    }
    body wm-mini-search-book-widget .wm-container .close span {
        color: #fff;
        text-shadow: none;
    }

    body .wm-container wm-results .result-text {
        display: none;
    }

    body .wm-container .bs-popover-auto[x-placement^=bottom] .arrow::before, 
    body .wm-container .bs-popover-bottom .arrow::before {
        top: 1px;
        border-bottom-color: #fff;
        filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.2));
    }
    body .wm-container .wm-checkbox-success input[type="checkbox"]:checked + label::before, 
    body .wm-container .wm-checkbox-success input[type="radio"]:checked + label::before {
        background-color: $zeb-color-primary;
        border-color: $zeb-color-primary;
    }

    // setting the 100% height
    body .wm-container wm-results .wm-results-list .wm-results-item.grid {
        align-self: stretch;
    }
    body .wm-container wm-results .wm-results-list .wm-results-item.grid .wm-card-content {
        height: 100%;
    }

// Styling extra
body wm-mini-search-book-widget .wm-container wm-type-selection .wm-type-selection-button .wm-selected-type {
    font-weight: normal;
}
body wm-mini-search-book-widget .wm-place-selection {
    background: #FFFFFF;
    padding: 0 0.625rem 0.625rem 0.625rem!important;
}
body wm-mini-search-book-widget .wm-place-selection a.clear-selection {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: $theme-color-primary;
    text-transform: lowercase;
}
body wm-mini-search-book-widget  .wm-place-selection label {
    font-size: 0.875rem;
    text-align: left;
    font-weight: 700;
    line-height: 1.2rem!important;
    margin-top: 8px;
    color: $theme-color-primary;
    margin-bottom: 0;
}
body wm-mini-search-book-widget .wm-place-selection input.form-control {
    border: 0;
    padding: 0;
    height: 20px;
    font-weight: 400;
    font-size: .75rem;
    margin-top: -3px;
    color: $theme-color-primary;
}
body wm-mini-search-book-widget  .wm-container wm-stay-selection .wm-type-selection-button.changed .wm-selected-type, 
body wm-mini-search-book-widget  .wm-container wm-type-selection .wm-type-selection-button.changed .wm-selected-type {
    color: $theme-color-primary;
    font-weight: 400;
    font-size: .75rem;
}
body wm-mini-search-book-widget  .wm-container wm-stay-selection .wm-type-selection-popover .wm-type-selection-popover-content .wm-type-selection-item.selected, 
body wm-mini-search-book-widget  .wm-container wm-stay-selection .wm-type-selection-popover .wm-type-selection-popover-content .wm-type-selection-item:hover, 
body wm-mini-search-book-widget  .wm-container wm-type-selection .wm-type-selection-popover .wm-type-selection-popover-content .wm-type-selection-item.selected, 
body wm-mini-search-book-widget  .wm-container wm-type-selection .wm-type-selection-popover .wm-type-selection-popover-content .wm-type-selection-item:hover {
    background: rgba(34, 176, 191, 0.25);
}
body wm-mini-search-book-widget .wm-container wm-filters-selection .popover {
    min-width: 230px!important;
}

@media only screen and (max-width: 980px) {
    body wm-mini-search-book-widget .wm-place-selection,
    body wm-mini-search-book-widget .wm-type-selection {
        flex: 1 1 100%;
        box-sizing: border-box;
        max-width: 100%;
    }
}

body.home wm-mini-search-book-widget .wm-place-selection,
body.home wm-mini-search-book-widget .wm-type-selection {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
}
body wm-mini-search-book-widget .wm-place-selection {
    height: auto;
    padding: 0.25rem;
    margin-right: -0.0625rem;
    margin-left: -0.0625rem;
    margin-bottom:5px;
    padding: 0 0.5rem 0.4rem 0.625rem;
    border-radius: 3px!important;
    border:none!important;
}
body wm-mini-search-book-widget .wm-place-selection label {
    margin-top: 5px;
}
body wm-mini-search-book-widget .wm-mini-search-book a.clear-selection::after {
    display:none;
}
body wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search {
    margin: 15px 0;
}
@media only screen and (min-width: 1200px) {
    body.home wm-mini-search-book-widget .popover {
        left: 0!important;
    }
}

@media only screen and (min-width: 980px) {
    body:not(.home) wm-mini-search-book-widget .wm-mini-search-book {   
        flex-wrap: nowrap!important;
    }
    body:not(.home) wm-mini-search-book-widget .wm-mini-search-book > div {
        flex: 1 1 auto!important; 
    }
    body:not(.home) wm-mini-search-book-widget .wm-mini-search-book > .travel-group-selection {
        min-width: 160px;
    } 
    body:not(.home) wm-mini-search-book-widget .wm-mini-search-book > .wm-date-selection {
        min-width: 170px;
    } 
    body:not(.home) wm-mini-search-book-widget .wm-mini-search-book > .wm-place-selection {
        min-width: 140px;
    }
    
}
@media only screen and (max-width: 1200px) {
    body:not(.home) wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search {
        max-width: 115px;
    }
    
}
@media only screen and (max-width: 979px) {
    body:not(.home) wm-mini-search-book-widget .wm-mini-search-book > div {
        flex: 1 1 100%!important;
        max-width: 100%!important;
        margin-right: 0;
        margin-left: 0;
    }
    body:not(.home) wm-mini-search-book-widget .wm-mini-search-book .wm-btn.wm-btn-search {
        width: 100%;
        max-width: 100%;
    }
}



// zoek en boek pagina

body wm-search-book-widget .wm-place-selection {
    height: 3.4375rem;
    border: 0.0625rem solid black;
    background: #FFFFFF;
    padding: 0.25rem;
    margin-right: -0.0625rem;
    padding: 0 0.625rem 0.625rem 0.625rem;
}
body wm-search-book-widget .wm-place-selection a.clear-selection {
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: $theme-color-primary;
    text-transform: lowercase;
}
body wm-search-book-widget  .wm-place-selection label {
    font-size: 0.875rem;
    text-align: left;
    font-weight: 700;
    line-height: 1.2rem!important;
    margin-top: 8px;
    color: $theme-color-primary;
    margin-bottom: 0;
}
body wm-search-book-widget .wm-place-selection input.form-control {
    border: 0;
    padding: 0;
    height: 20px;
    font-weight: 400;
    font-size: .75rem;
    margin-top: -3px;
    margin-right: 20px;
    color: $theme-color-primary;
}
body wm-search-book-widget  .wm-container wm-stay-selection .wm-type-selection-button.changed .wm-selected-type, 
body wm-search-book-widget  .wm-container wm-type-selection .wm-type-selection-button.changed .wm-selected-type,
body wm-search-book-widget  .wm-container wm-type-selection .wm-type-selection-button .wm-selected-type {
    color: $theme-color-primary;
    font-weight: 400;
    font-size: .75rem;
}
body wm-search-book-widget  .wm-container wm-stay-selection .wm-type-selection-popover .wm-type-selection-popover-content .wm-type-selection-item.selected, 
body wm-search-book-widget  .wm-container wm-stay-selection .wm-type-selection-popover .wm-type-selection-popover-content .wm-type-selection-item:hover, 
body wm-search-book-widget  .wm-container wm-type-selection .wm-type-selection-popover .wm-type-selection-popover-content .wm-type-selection-item.selected, 
body wm-search-book-widget  .wm-container wm-type-selection .wm-type-selection-popover .wm-type-selection-popover-content .wm-type-selection-item:hover {
    background: rgba(34, 176, 191, 0.25);
}
body wm-search-book-widget .wm-container wm-filters-selection .wm-filter-selection-button .wm-selected-filter {
    line-height: 1.2;
}


@media only screen and (max-width: 980px) {
    body wm-search-book-widget .wm-place-selection,
    body wm-search-book-widget .wm-type-selection {
        flex: 1 1 100%;
        box-sizing: border-box;
        max-width: 100%;
    }
}